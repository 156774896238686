import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const FooterContainer = styled.footer`
    ${tw`w-full py-8 px-8 sm:px-10 divide-y border border-solid`}
`;

const FooterDiv = styled.div`
    ${tw`mx-auto max-w-screen-xl sm:flex text-center sm:text-left justify-between px-8 sm:px-10`}

    p {
        ${tw`mb-4 sm:mb-2`}
    }
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterDiv>
                <p>
                    Developed by <a href="https://www.linkedin.com/in/bainstaranveer/">Taranveer Bains</a>
                </p>
                <div>
                    <p>
                        © BKB Nutrition {new Date().getFullYear()}, Built with
                        {` `}
                        <a href="https://www.gatsbyjs.com">Gatsby</a>
                    </p>
                    <p style={{ margin: 0 }}>
                        <Link to="/terms">Terms of service</Link>
                    </p>
                </div>
            </FooterDiv>
        </FooterContainer>
    );
};

export default Footer;
