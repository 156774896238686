import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import BacgkroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const ImageBackground = styled(BacgkroundImage)`
    background-position: top 20% center;
    background-size: cover;
    height: 75vh;
    @media (min-width: 640px) {
        height: 85vh;
    }
`;

const TextBox = styled.div`
    background-image: linear-gradient(to top, #000000dd 2rem, #00000033);
    ${tw`relative pb-16 h-full w-full justify-end items-end flex flex-wrap flex-col`}

    h1 {
        text-shadow: 1px 1px 3px #eeddff66;
        ${tw`text-6xl text-white leading-none mb-2`}
    }

    p {
        text-shadow: 1px 1px 3px #eeddff66;
        ${tw`text-white text-2xl mt-0 leading-none`}
    }
`;

const TextContainer = styled.div`
    ${tw`absolute w-full max-w-screen-xl text-right p-10`}
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
`;

const Hero = () => {
    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "training.jpg" }) {
                sharp: childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    return (
        <ImageBackground fluid={image.sharp.fluid}>
            <TextBox>
                <TextContainer>
                    <h1>BKB Nutrition</h1>
                    <p>Sports nutrition and training programs</p>
                </TextContainer>
            </TextBox>
        </ImageBackground>
    );
};

export default Hero;
