import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import logo from '../../images/BKB.svg';

const StyledHeader = styled.header`
    ${tw`sticky bg-white top-0 z-10`}
    box-shadow: 0 4px 1px -4px black
`;

const StyledInnerContainer = styled.nav`
    ${tw`max-w-screen-xl justify-between items-center flex flex-no-wrap m-auto px-8 sm:px-10`}
`;

const LogoContainer = styled.div`
    width: 75px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const LinksConatiner = styled.div`
    ${tw``}
`;

const StyledLink = styled(Link)`
    ${tw`font-semibold text-2xl mr-4 sm:mr-8 hover:opacity-75 transition-opacity duration-300`}

    &:last-of-type {
        ${tw`mr-0`}
    }
`;

type Props = {
    siteTitle?: string;
};
const Header = ({ siteTitle = '' }: Props) => (
    <StyledHeader>
        <StyledInnerContainer>
            <LogoContainer>
                <Link to="/">
                    <img src={logo} alt={`${siteTitle} Logo`} />
                </Link>
            </LogoContainer>
            <LinksConatiner>
                {/* <StyledLink to="/#plans">DIET PLANS</StyledLink> */}
                <StyledLink to="/#about">ABOUT</StyledLink>
                <StyledLink to="/#contact">CONTACT</StyledLink>
            </LinksConatiner>
        </StyledInnerContainer>
    </StyledHeader>
);

export default Header;
