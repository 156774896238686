import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';

export const StyledGlobalStyle = createGlobalStyle`
  html, body {
      ${tw`font-normal text-black font-sans leading-none scrolling-touch`}
      font-size: 14px;
  }

  body {
      ${tw`overflow-scroll text-base p-0 m-0`}
  }

  h1,h2,h3,h4,h5,h6 {
    ${tw`font-semibold`}
  }

  h1 {
    ${tw`mb-4 text-6xl`}
  }

  h2 {
    ${tw`text-5xl`}
  }

  h3 {
    ${tw`text-4xl`}
  }

  h4 {
    ${tw`text-3xl`}
  }

  a {
    ${tw`hover:opacity-75 transition-opacity duration-300`}
  }

  p {
    ${tw`my-8 leading-8 text-xl`}
  }

  hr {
    ${tw`border-black`}
  }
`;
