/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactNode } from 'react';
import tw from 'twin.macro';
import { StyledGlobalStyle } from '../../utils/styling';
import { GlobalStyles } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../Header';
import Hero from '../Hero';
import Footer from '../Footer';

type Props = {
    children: ReactNode;
    showHero?: boolean;
};

if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
}

const Main = tw.main``;

const Layout = ({ children, showHero }: Props) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <GlobalStyles />
            <StyledGlobalStyle />
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            {showHero && <Hero />}
            <div>
                <Main>{children}</Main>
                <Footer />
            </div>
        </>
    );
};

export default Layout;
